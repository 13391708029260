<template>
  <div id="eventDetailPage">
    <q-dialog v-model="openPopup"
              transition-hide="slide-down"
              transition-show="slide-up"
    >
      <q-card :class="eventDetail.layout"
              class="relative-position overflow-hidden no-scroll hide-scrollbar">
        <q-card-section :class="eventDetail.maxHeight"
                        class="scroll">
          <p :class="eventDetail.contents"
             class="text-caption_normal no-margin q-pt-md"
             v-html="eventContents.content"/>
        </q-card-section>
        <q-card-section :class="eventDetail.bottom"
                        class="bg-color_white no-margin no-padding row full-width items-center justify-end">
          <q-btn v-close-popup
                 :class="eventDetail.button"
                 class="bg-color_vitoBlue q-mr-sm"
                 flat
                 @click="closePopup">
            <p class="text-caption_large text-color_white no-margin no-padding">
              {{ $attrs.lang.close }}
            </p>
          </q-btn>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script setup>
// Imports

import { computed, reactive } from 'vue'
import { useStore } from 'vuex'

// Props

// Emits

// Variables

const store = useStore()

// Responses

const openPopup = reactive(computed(() => store.getters.getEventDetailPopup))
const eventContents = reactive(computed(() => store.getters.getEvent))

// Methods

const closePopup = () => store.commit('showEventDetailPopup', false)

// Lifecycles

</script>

<style module="eventDetail">

.layout {
  width: 100vw;
  max-width: 500px !important;
  height: 70vh;
  max-height: 600px !important;
  padding: 0;
}

.maxHeight {
  height: 100%;
}

.bottom {
  position: sticky !important;
  height: 60px;
  bottom: -1px;
  border-top: 1px solid #f3f3f3;
  width: 100%;
  padding: 0;
}

.button {
  height: 100%;
  max-height: 40px;
}

.contents * {
  font-size: 14px;
}

.contents div, .contents span, .contents a {
  padding: 0;
  margin: 0;
}

.contents h2 {
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}
</style>
